/* eslint-disable max-len */
/* eslint-disable react/style-prop-object */
import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Layout from '../components/layout';

import Hero from '../components/hero';

const PrivacyPolicy = () => (
  <StaticQuery
    query={graphql`
      query PrivacyQuery {
        allContentfulHomepage {
          edges {
            node {
              pageTitle

              heroImage {
                file {
                  url
                }
              }

              heroSection {
                content {
                  content {
                    value
                  }
                }
              }

              aboutImage {
                file {
                  url
                }
              }

              aboutSection {
                content {
                  content {
                    value
                  }
                }
              }

              solutionsImage {
                file {
                  url
                }
              }

              solutionsSection {
                content {
                  content {
                    value
                  }
                }
              }

              meta {
                meta {
                  content
                  name
                }
              }
            }
          }
        }
      }
    `}

    render={(data) => {
      const graphqlData = data.allContentfulHomepage.edges[0].node;

      return (
        <Layout>
          <section id="privacy">
            <Hero
              image={graphqlData.heroImage.file.url}
              data={graphqlData.heroSection.content}
            />

            <section className="container text--center margin--bottom margin--top padding--bottom padding--top text--left">
              <h1><strong>Upendo Staffing - Recruitment Agency Privacy Policy</strong></h1>
              <p><span>Last Updated: January 2024</span></p>
              <p><span>1. Introduction</span></p>
              <p>
                <span>
                Upendo Staffing is committed to protecting the privacy of our candidates, clients, and website visitors.
                This privacy policy explains how we collect, use, disclose, and safeguard your personal information.
                </span>
              </p>
              <p><span>2. Information Collection</span></p>
              <p><span>We collect personal information when you:</span></p>
              <p><span>Register with us or submit your CV/resume. Contact us directly via email, phone, or other means. Use our website or services.</span></p>
              <p><span>The types of personal information we collect include:</span></p>
              <p><span>Contact details (name, email address, phone number).Professional information (CV, employment history, qualifications, job requirements, etc).</span></p>
              <p><span>3. Use of Information</span></p>
              <p><span>Your personal information is used to:</span></p>
              <p><span>Provide recruitment services and match you with potential employment opportunities. Communicate with you regarding recruitment opportunities and updates. Improve our services and website. Share information with you as a representative of your company, and a client or prospective client of Upendo Staffing, LLC regarding candidates, market insights, trainings, other valuable information as requested.</span></p>
              <p><span>4. Disclosure of Information</span></p>
              <p><span>We may disclose your personal information to:</span></p>
              <p><span>Potential employers or clients for recruitment purposes. Service providers who assist in our operations. Legal authorities if required by law.</span></p>
              <p><span>We do not sell your personal information to third parties.</span></p>
              <p><span>5. Data Security</span></p>
              <p><span>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</span></p>
              <p><span>6. Your Rights</span></p>
              <p><span>You have the right to:</span></p>
              <p><span>Access, update, or delete the personal information we hold about you. Object to the processing of your personal information. Withdraw consent for the use of your personal information, where applicable.</span></p>
              <p><span>7. Changes to This Policy</span></p>
              <p><span>We may update this privacy policy from time to time. We will notify you of any significant changes by posting the new policy on our website.</span></p>
              <p><span>8. Contact Us</span></p>
              <p><span>If you have any questions about this privacy policy or our treatment of your personal information, please contact us. ATTN: PRIVACY</span></p>
            </section>
          </section>
        </Layout>
      );
    }}
  />
);

export default PrivacyPolicy;
